#contact-us-page-sec-02-wrap {
  padding-top: 32px;
}

#contact-us-page-sec-02-title {
  color: #033361;
  text-align: center;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.24px;
}

#contact-us-page-sec-02-sub-title {
  color: rgba(3, 3, 3, 0.7);
  text-align: center;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 80%;
  margin: auto;
  margin-top: 5px;
}

#contact-us-page-sec-02-form-name-input {
  height: 40px;
  padding-left: 15px;
  width: 80%;
  margin: auto;
  margin-top: 12px;
  border-radius: 5px;
  border: 0.5px solid rgba(90, 84, 84, 0.65);
  color: rgba(90, 84, 84, 0.81);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.014px;
}

#contact-us-page-sec-02-form-email-input {
  height: 40px;
  padding-left: 15px;
  width: 80%;
  margin: auto;
  margin-top: 12px;
  border-radius: 5px;
  border: 0.5px solid rgba(90, 84, 84, 0.65);
  margin-top: 25px;
  color: rgba(90, 84, 84, 0.81);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.014px;
}

#contact-us-page-sec-02-form-wrap {
  margin-top: 32px;
}

#contact-us-page-sec-02-form-tel-code-input {
  width: 10%;
  height: 35px;
  padding-left: 15px;
  border-radius: 5px;
  border: 0.5px solid rgba(90, 84, 84, 0.65);
  background: #d5d6d7;
  color: rgba(3, 3, 3, 0.65);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.014px;
}

#contact-us-page-sec-02-form-phone-input {
  width: 60%;
  height: 40px;
  border-radius: 5px;
  border: 0.5px solid rgba(90, 84, 84, 0.65);
  margin-top: 25px;
  margin-left: 20px;
  padding-left: 15px;
  color: rgba(90, 84, 84, 0.81);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.014px;
}

#contact-us-page-sec-02-form-message-input{
    margin-top: 25px;
    border-radius: 5px;
  border: 0.5px solid rgba(90, 84, 84, 0.65);
  padding-left: 15px;
  padding-top: 15px;
  width: 80%;
  color: rgba(90, 84, 84, 0.81);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.014px;
}

#contact-us-page-sec-02-form-text{
    color: rgba(3, 3, 3, 0.72);
font-family: "Nunito";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px;
width: 86%;
margin: auto;
text-align: left;
margin-top: 7px;
}

#contact-us-page-sec-02-form-submit-button{
    border-radius: 5px;
border: 0.5px solid #087AE9;
background: #087AE9;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
color: #FFF;
font-family: "Inder";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 2px;
padding: 10px;
width: 30%;
margin-top: 25px;
}

#contact-us-page-sec-02-form-submit-button-wrap{
    width: 92%;
    display: flex;
    justify-content: right;
}

#contact-us-page-sec-02-form>div>input:focus , #contact-us-page-sec-02-form>div>textarea:focus{
    outline: none;
border: 0.5px solid #1A85EB;
}

#contact-us-page-banner-wrap{
    background: #DCDDE2;
    padding-bottom: 60px;
    padding-top: 20px;
}

#contact-us-page-banner-image-wrap{
    width:40%;
    margin: auto;
}

#contact-us-page-banner-image-wrap>img{
    width:100%;
    height:100%;
    object-fit: cover;
}

#contact-us-page-banner-text-content{
    color: #033361;
text-align: center;
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.14px;
width: 75%;
margin: auto;
margin-top: 15px;
}

#contact-us-page-banner-button{
    border-radius: 5px;
border: 0.5px solid #087AE9;
background: #087AE9;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
color: #FFF;
font-family: "Inder";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 2px;
padding: 8px 14px 8px 14px;
margin-top: 25px;
}