#footer-sec01-wrap{
    display: flex;
    padding-top: 10px;
    border-top: 0.5px solid rgba(26, 133, 235, 0.59);
    padding-left: 17px;
}

#footer-main-wrapper{
    margin-top: 70px;
}

#footer-sec01-list01-wrap,#footer-sec01-list02-wrap{
    color: #0668C7;
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 21px;
letter-spacing: 0.035px;
text-align: left;
width: 45%;
}

#footer-sec01-list01-wrap>:nth-child(1),#footer-sec01-list02-wrap>:nth-child(1){
    color: #033361;
font-family: Nunito;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.023px;
}

#footer-sec01-list01-wrap>div,#footer-sec01-list02-wrap>div{
    margin-top:13px;
}

#footer-sec01-list01-wrap>a>div,#footer-sec01-list02-wrap>a>div{
    margin-top:13px;
}

#footer-sec01-list01-wrap>a,#footer-sec01-list02-wrap>a{
    margin-top:13px;
    text-decoration: none;
       color: #0668C7;
}

#footer-sec02-wrap{
    margin-top: 10px;
}

#footer-sec02-wrap>:nth-child(1){
    background: #1A85EB;
    display: flex;
    padding: 12px 12px 12px 16px;
    justify-content: space-between;
    color: #FFF;
font-family: "Nunito";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.024px;
}

#footer-sec02-drop-icon{
    font-size: 25px;
}

#footer-sec02-wrap>:nth-child(2)>a>div{
    color: #1A85EB;
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 21px;
letter-spacing: 0.035px;
padding: 12px 0px 12px 16px;
text-align: left;
border-bottom: 0.5px solid #707070E5;
}

#footer-sec02-wrap>:nth-child(2)>a{
  text-decoration: none;
}

#footer-sec03-wrap{
    padding-top: 23px;
    background: #1A85EB;
    padding-bottom: 23px;
    margin-top: 3px;
}

#footer-sec03-wrap>:nth-child(1){
    color: #FFF;
font-family: "Nunito";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.023px;
}

#footer-sec03-heart-icon{
    color: red;
    margin-left: 5px;
     margin-right: 5px;
}

#footer-sec03-sc-media-icons-wrap{
    display: flex;
    width: 30%;
    margin: auto;
    margin-top: 40px;
    justify-content: space-between;
}

#footer-sec01-desktop-address-wrap{
     display: none;
}

@media only screen and (min-width: 1224px) {
#footer-sec01-desktop-address-wrap{
     display: block;
     text-align: left;
     width: 65%;
}

.footer-sec01-desktop-view-titles{
color: #033361;
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.027px;
}

.footer-sec01-desktop-view-content{
    color: rgba(3, 3, 3, 0.80);
font-family: "Nunito";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px;
letter-spacing: 0.04px;
width: 48%;
margin-top: 8px;
}

#footer-sec01-desktop-view-contact-title{
    margin-top: 30px;
}

#footer-sec01-desktop-view-mail-title{
    margin-top: 30px;
}

#footer-sec01-desktop-view-mail-content{
    color: #1A85EB;
}

#footer-sec01-wrap{
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 70px;
}

#footer-sec01-list01-wrap,#footer-sec01-list02-wrap{
    color: #0668C7;
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 21px;
letter-spacing: 0.035px;
text-align: left;
width: 15%;
}

#footer-sec03-wrap>:nth-child(1){
text-align: right;
margin-right: 70px;
}

#footer-sec03-sc-media-icons-wrap{
    display: flex;
    width: 10%;
    margin: auto;
    margin-top: 20px;
    justify-content:space-evenly;
    margin-right: 60px;
}
}