#about-us-sec01-wrap{
    width: 100%;
}

#about-us-sec01-wrap>img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

#about-us-sec02-wrap>:nth-child(1),#about-us-sec02-wrap>:nth-child(2){
    color: #1A85EB;
font-family: "Nunito";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 25px;
letter-spacing: 0.225px;
text-align: left;
}

#about-us-sec02-wrap{
margin-top: 23px;
padding-left: 16px;
padding-right: 16px;
}

#about-us-sec02-wrap>:nth-child(3){
color: #000;
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 20px;
text-align: left;
}

#about-us-sec03-wrap{
    margin-top: 23px;
    padding-left: 16px;
padding-right: 14px;
}

#about-us-sec03-wrap>:nth-child(1){
    color: #033361;
font-family: "Nunito";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 25px;
letter-spacing: 0.225px;
}

#about-us-sec03-wrap>:nth-child(2){
    color: rgba(3, 3, 3, 0.90);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
margin-top: 10px;
}

#about-us-sec03-wrap>:nth-child(3){
    color: rgba(3, 3, 3, 0.90);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
margin-top: 20px;
}

#about-us-sec04-wrap{
  width: 60%;
  margin: auto;
  margin-top: 60px;
}

#about-us-sec04-wrap>img{
  width:100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 1224px) {
 #about-us-sec01-wrap{
    height: 450px;
}

#about-us-sec01-wrap>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#about-us-sec02-wrap>:nth-child(1),#about-us-sec02-wrap>:nth-child(2){
color: #1A85EB;
text-align: center;
font-family: Nunito;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 45px; /* 150% */
letter-spacing: 0.45px;
}


#about-us-sec02-wrap>:nth-child(3){
color: #000;
font-family: "Nunito";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 70%;
margin: auto;
margin-top: 30px;
}

#about-us-sec03-wrap>:nth-child(1){
color: #033361;
font-family: Nunito;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 78.125% */
letter-spacing: 0.48px;
margin-top: 50px;
}


#about-us-sec03-wrap>:nth-child(2){
color: rgba(3, 3, 3, 0.90);
font-family: "Nunito";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 70%;
margin: auto;
margin-top: 50px;
}

#about-us-sec03-wrap>:nth-child(3){
color: rgba(3, 3, 3, 0.90);
font-family: "Nunito";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 70%;
margin:auto;
margin-top: 30px;
}

#about-us-sec04-wrap{
  width: 40%;
  margin: auto;
  margin-top: 60px;
}

#about-us-sec04-wrap>img{
  width:100%;
  height: 100%;
  object-fit: cover;
}
}