#pillow-category-page-main-wrapper {
  padding: 10px 16px 0px 16px;
}

#pillow-category-page-banner-image-wrap {
  width: 93%;
  height: 200px;
  border-radius: 8px;
  border: 0.1px solid #707070;
  background: #fff;
  padding: 6px;

}

#pillow-category-page-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}

#pillow-category-page-sec01-wrap {
  padding: 12px 11px 0px 12px;
  background: rgba(170, 202, 233, 0.12);
  padding-bottom: 18px;
  border-radius: 5px;
}

#pillow-category-page-banner-sub-images-wrap > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#pillow-category-page-product-title {
    color: #032D56;
font-family: "Nunito";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.225px;
text-align: left;
margin-top: 20px;
}

#pillow-category-page-product-tagline{
    color: rgba(0, 0, 0, 0.65);
font-family: "Nunito";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.013px;
text-align: left;
margin-top: 10px;
}

#pillow-category-page-product-rating{
    margin-top: 12px;
}

#pillow-category-page-product-shop-btn{
    border-radius: 5px;
border: 0.5px solid #087AE9;
background: #087AE9;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
width: 100%;
color: #FFF;
font-family: "Inder";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 2px;
text-transform: uppercase;
padding: 8px 0px 8px 0px;
margin-top: 16px;
}

#pillow-category-page-sec02-descp-wrap{
    padding: 21px 0px 46px 13px;
    border-radius: 5px;
background: rgba(170, 202, 233, 0.12);
margin-top: 5px;
}

#pillow-category-page-sec02-descp-headline{
    color: #032D56;
font-family: "Nunito";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.225px;
text-align: left;
}

#pillow-category-page-sec02-descp-content{
    color: rgba(3, 3, 3, 0.65);
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.21px;
text-align: left;
margin-top: 12px;
}

#pillow-category-page-sec03-product-details-wrap{
    padding: 21px 0px 18px 16px;
    border-radius: 5px;
background: rgba(170, 202, 233, 0.34);
margin-top: 5px;
}

#pillow-category-page-sec03-product-details-headline{
    color: #032D56;
font-family: "Nunito";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.225px;
text-align: left;
}

.pillow-category-page-sec03-product-details-flex-logo-wrap{
    width: 33px;
    height: 33px;
}

.pillow-category-page-sec03-product-details-flex-logo-wrap>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pillow-category-page-sec03-product-details-flex-wrap{
    display: flex;
}

#pillow-category-page-sec03-product-details-content-wrap{
    margin-top: 23px;
}

.pillow-category-page-sec03-product-details-flex-details-wrap>:nth-child(1){
    color: #032D56;
font-family: "Nunito";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.28px;
text-align: left;
}

.pillow-category-page-sec03-product-details-flex-details-wrap{
margin-left: 22px;
margin-bottom: 23px;
}

.pillow-category-page-sec03-product-details-flex-details-wrap>:nth-child(2){
    color: #032D56;
font-family: Nunito;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.26px;
text-align: left;
}

@media only screen and (min-width: 1224px) {

#pillow-category-page-desktop-view-flex01-wrap{
  display: flex;
  justify-content: space-around;
  background: rgba(170, 202, 233, 0.12);
  margin-top: 40px;
}

#pillow-category-page-desktop-view-flex01-wrap>:nth-child(1){
  /* border: 1px solid red; */
  width: 60%;
}

#pillow-category-page-desktop-view-flex01-wrap>:nth-child(2){
  /* border: 1px solid red; */
  width: 40%;
}


#pillow-category-page-product-title {
color: #032D56;
font-family: Nunito;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.5px;
}

#pillow-category-page-product-tagline{
 color: rgba(3, 3, 3, 0.65);
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.018px;
width: 70%;
}

#pillow-category-page-product-shop-btn{
margin-top: 30px;
}


#pillow-category-page-sec02-descp-wrap{
    padding: 21px 0px 46px 13px;
    border-radius: 5px;
background: rgba(170, 202, 233, 0.12);
margin-top: 5px;
}

#pillow-category-page-sec02-descp-headline{
  color: #033361;
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.018px;
}

#pillow-category-page-sec02-descp-content{
   color: rgba(3, 3, 3, 0.65);
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.27px;
width: 85%;
}

#pillow-category-page-desktop-view-flex02-wrap{
    display: flex;
    margin-top: 30px;
}

#pillow-category-page-desktop-view-flex02-wrap>:nth-child(1){
    width: 50%;
    background: rgba(170, 202, 233, 0.34);
    padding-top: 40px;
}

#pillow-category-page-desktop-view-flex02-wrap>:nth-child(2){
    width: 50%;
    background: rgba(170, 202, 233, 0.34);
    margin-top: 5px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: 5px;
}

#pillow-category-page-sec03-product-details-headline{
 color: #033361;
font-family: Nunito;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.5px;
text-align: center ;
}

#pillow-category-page-sec04-headline{
color: #032D56;
font-family: Nunito;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.375px;
}

#pillow-category-page-banner-image-wrap {
  height:500px;
}

#pillow-category-page-sec03-product-details-content-wrap{
    display: flex;
    width: 50%;
    margin: auto;
    justify-content: space-around;
    margin-top: 40px;
}

#pillow-category-page-sec03-product-details-wrap{
    padding: 40px 0px 40px 16px;
    border-radius: 5px;
background: rgba(170, 202, 233, 0.34);
margin-top: 5px;
}

.pillow-category-page-sec03-product-details-flex-details-wrap>:nth-child(1){
   color: #033361;
font-family: Nunito;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.4px;
}

.pillow-category-page-sec03-product-details-flex-details-wrap>:nth-child(2){
color: #033361;
font-family: Nunito;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.36px;
}

}