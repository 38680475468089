.bestseller-page-banner-slider-title {
  color: #fff;
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bestseller-page-banner-slider01 {
  background: url("../bg-img/pillow/banner01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.bestseller-page-banner-slider02 {
  background: url("../bg-img/pillow/banner02.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.bestseller-page-banner-slider03 {
  background: url("../bg-img/pillow/banner03.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.bestseller-page-banner-slider-quote {
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  width: 75%;
  margin: auto;
  margin-top: 10px;
}

.bestseller-page-banner-slider-request-btn {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-size: 10px;
  font-family: "Inder", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 6px;
  margin-top: 26px;
  padding-left: 10px;
  padding-right: 10px;
}

#bestseller-page-banner-slider-wrap {
  height: 296px;
}

.bestseller-banner-slider-component {
  height: 296px;
}

.bestseller-banner-slider-component > .slick-dots > li {
  margin-bottom: 50px;
}

.bestseller-banner-slider-component > .slick-dots > li > button:before {
  font-size: 10px;
  color: #ffffff;
}

#bestseller-page-content-wrap{
padding-top: 40px;
}

#bestseller-page-content-headline{
    color: #032D56;
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.27px;
}


.bestseller-page-content-grid {
  border-radius: 5px;
  border: 0.5px solid rgba(112, 112, 112, 0.37);
  background: #fff;
  box-shadow: 1px 1px 3px 2px rgba(75, 160, 242, 0.12);
  padding: 5px;
  padding-bottom: 20px;
}

.bestseller-page-content-grid-img-wrap {
  width: 100%;
  height: 147px;
}

.bestseller-page-content-grid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.bestseller-page-content-grid-title {
  color: #000;
  text-align: center;
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.013px;
  width: 60%;
  margin: auto;
  margin-top: 15px;
}

.bestseller-page-content-grid-rating {
  width: max-content;
  margin: auto;
  margin-top: 10px;
}

.bestseller-page-content-grid-description {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-family: "Nunito";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

#bestseller-page-content-grid-wrap{
    display: grid;
    grid-template-columns: 48.5% 48.5%;
    grid-template-rows: auto;
    column-gap: 12px;
    row-gap: 20px;
    margin-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
}