#navigation-list-main-wrapper{
    border-radius: 0px 39px 18px 0px;
background: #087AE9;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
width:80%;
padding-left: 17px;
padding-right: 28px;
padding-bottom: 23px;
position: absolute;
z-index: 999999;
top: 30px;
padding-top: 80px;
  left: -100%;
  animation: slideInFromLeft 1s forwards;
}

@keyframes slideInFromLeft {
  0% {
    left: -100%; /* Starting position, outside the viewport */
  }
  100% {
    left: 0; /* Ending position, slide into the viewport from the left */
  }
}

.navigation-list-title-wrap{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    padding-left: 16px;
    padding-right: 30px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.44);
}

.navigation-list-title{
    color: #FFF;
font-family: "Poppins";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.045px;
text-align: left;
}

.navigation-list-add-icon{
    color: #FFF;
    font-size: 20px;
}

.navigation-list-category-title-wrap{
        display: flex;
    justify-content: space-between;
    padding: 16px;
    padding-left: 16px;
    padding-right: 30px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.44);
}

.navigation-list-category-name{
        color: #FFF;
font-family: "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.045px;
text-align: left;
}

.navigation-list-sub-category{
    color: #FFF;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0.035px;
text-align: left;
    padding: 16px;
    padding-left: 16px;
    padding-right: 30px;
border-bottom: 0.5px solid rgba(255, 255, 255, 0.44);
}

.navigation-list-button{
    border-radius: 5px;
border: 0.5px solid #1A85EB;
background: #033361;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
color: #FFF;
font-family: "Inder";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 2px;
text-transform: uppercase;
width: 95%;
margin: auto;
padding-top: 12px;
padding-bottom: 12px;
margin-top: 20px;
}

#navigation-list-close-button{
  position: absolute;
  top: 30px;
  left: 25px;
  font-size: 28px;
  color: #FFF;
}