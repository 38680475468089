#customise-main-page-heading {
  color: #032d56;
  font-family: "Nunito";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}

#customise-main-page-sub-heading {
  color: rgba(3, 3, 3, 0.7);
  font-family: "Nunito";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px; /* 208.333% */
}

#customise-main-page-custom-selection-size-title {
  color: #000;
  font-family: "Nunito";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02px;
}

#customise-main-page-custom-selection-wrap {
  width: 70%;
  margin: auto;
  border-radius: 21px;
  background: rgba(221, 237, 252, 0.29);
  padding: 100px 63px 63px 123px;
  text-align: left;
  margin-top: 20px;
}


.customise-main-page-custom-selection-size-content{
  display: flex;
border-radius: 20px;
border: 0.5px solid rgba(112, 112, 112, 0.58);
padding: 11px 20px 11px 20px;
justify-content: space-between;
width: 16%;
cursor: pointer;
}

.customise-main-page-custom-selection-size-content:active{
  background-color: #1A85EB;
}

#customise-main-page-custom-selection-size-content-wrap{
  display: flex;
  margin-top: 20px;
  width: 80%;
  justify-content: space-between;
}

.customise-main-page-custom-selection-size-content>:nth-child(2){
  width: 20%;
  height: 20px;
}

.customise-main-page-custom-selection-size-content>:nth-child(2)>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customise-main-page-custom-selection-size-content>:nth-child(1){
color: rgba(3, 3, 3, 0.63);
font-family: "Inder";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.016px;
}

#customise-main-page-custom-selection-dimension-title {
  color: #000;
  font-family: "Nunito";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02px;
}


.customise-main-page-custom-selection-dimension-content{
  display: flex;
border-radius: 20px;
border: 0.5px solid rgba(112, 112, 112, 0.58);
padding: 11px 20px 11px 20px;
justify-content: space-between;
margin-right: 20px;
cursor: pointer;
}

#customise-main-page-custom-selection-dimension-content-wrap{
display: grid;
  margin-top: 20px;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: auto;
  row-gap: 22px;
}

.customise-main-page-custom-selection-dimension-content:active{
  background-color: #1A85EB;
}

.customise-main-page-custom-selection-dimension-content>:nth-child(2){
  width: 20%;
  height: 20px;
}

.customise-main-page-custom-selection-dimension-content>:nth-child(2)>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customise-main-page-custom-selection-dimension-content>:nth-child(1){
color: rgba(3, 3, 3, 0.63);
font-family: "Inder";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.016px;
}

#customise-main-page-custom-selection-dimension-wrap{
  margin-top: 50px;
}


#customise-main-page-custom-selection-thickness-title {
  color: #000;
  font-family: "Nunito";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02px;
}


.customise-main-page-custom-selection-thickness-content{
  display: flex;
border-radius: 20px;
border: 0.5px solid rgba(112, 112, 112, 0.58);
padding: 11px 20px 11px 20px;
justify-content: space-between;
width: 15%;
margin-right: 20px;
cursor: pointer;
}

.customise-main-page-custom-selection-thickness-content:active{
  background-color: #1A85EB;
}

#customise-main-page-custom-selection-thickness-content-wrap{
  display: flex;
  margin-top: 20px;
  width: 80%;
}

.customise-main-page-custom-selection-thickness-content>:nth-child(2){
  width: 20%;
  height: 20px;
}

.customise-main-page-custom-selection-thickness-content>:nth-child(2)>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customise-main-page-custom-selection-thickness-content>:nth-child(1){
color: rgba(3, 3, 3, 0.63);
font-family: "Inder";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.016px;
}

#customise-main-page-custom-selection-thickness-wrap{
  margin-top: 50px;
}



#customise-main-page-custom-selection-toplayer-title {
  color: #000;
  font-family: "Nunito";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02px;
}


.customise-main-page-custom-selection-toplayer-content{
  display: flex;
border-radius: 20px;
border: 0.5px solid rgba(112, 112, 112, 0.58);
padding: 11px 20px 11px 20px;
justify-content: space-between;
margin-right: 20px;
cursor: pointer;
}

.customise-main-page-custom-selection-toplayer-content:active{
  background-color: #1A85EB;
}

#customise-main-page-custom-selection-toplayer-content-wrap{
display: grid;
  margin-top: 20px;
  grid-template-columns: 40% 40%;
  grid-template-rows: auto;
  row-gap: 22px;
}

.customise-main-page-custom-selection-toplayer-content>:nth-child(2){
  width: 20%;
  height: 20px;
}

.customise-main-page-custom-selection-toplayer-content>:nth-child(2)>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customise-main-page-custom-selection-toplayer-content>:nth-child(1){
color: rgba(3, 3, 3, 0.63);
font-family: "Inder";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.016px;
}

#customise-main-page-custom-selection-toplayer-wrap{
  margin-top: 50px;
}


#customise-main-page-custom-selection-bottomlayer-title {
  color: #000;
  font-family: "Nunito";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02px;
}


.customise-main-page-custom-selection-bottomlayer-content{
  display: flex;
border-radius: 20px;
border: 0.5px solid rgba(112, 112, 112, 0.58);
padding: 11px 20px 11px 20px;
justify-content: space-between;
margin-right: 20px;
cursor: pointer;
}

.customise-main-page-custom-selection-bottomlayer-content:active{
  background-color: #1A85EB;
}


#customise-main-page-custom-selection-bottomlayer-content-wrap{
display: grid;
  margin-top: 20px;
  grid-template-columns: 38% 38%;
  grid-template-rows: auto;
  row-gap: 22px;
}

.customise-main-page-custom-selection-bottomlayer-content>:nth-child(2){
  width: 20%;
  height: 20px;
}

.customise-main-page-custom-selection-bottomlayer-content>:nth-child(2)>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customise-main-page-custom-selection-bottomlayer-content>:nth-child(1){
color: rgba(3, 3, 3, 0.63);
font-family: "Inder";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.016px;
}

#customise-main-page-custom-selection-bottomlayer-wrap{
  margin-top: 50px;
}

#customise-main-page-custom-selection-cancel-button{
  border-radius: 8px;
border: 0.5px solid #1A85EB;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
color: #0668C7;
font-family: "Inder";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 2px;
text-transform: uppercase;
padding: 8px 0px 8px 0px;
width: 15%;
}

#customise-main-page-custom-selection-next-button{
  border-radius: 8px;
border: 0.5px solid #1A85EB;
background: #1A85EB;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
color: #FFF;
font-family: "Inder";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 2px;
text-transform: uppercase;
padding: 8px 0px 8px 0px;
width: 15%;
margin-left: 25px;
cursor: pointer;
}

#customise-main-page-custom-selection-buttons-wrap{
  text-align: right;
  margin-top: 20px;
}

.customise-main-page-error-message{
  color: red;
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.036px;
margin-top: 20px;
display: none;
}