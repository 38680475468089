#navbar-header-div {
  height: 24px;
  background: #033361;
}

#navbar-elements-container {
  display: flex;
  height: 40px;
  background: #087ae9;
  color: white;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

#navbar-elements-hamburger-icon {
  font-size: 30px;
  margin-left: 20px;
}

#navbar-elements-search-icon {
  font-size: 30px;
  margin-right: 20px;
}

#navbar-elements-logo-wrap {
  width: 35%;
}

#navbar-elements-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#navbar-desktop-view-main-wrapper {
  display: none;
}

@media only screen and (min-width: 1224px) {
  #navbar-main-wrapper {
    display: none;
  }

  #navbar-desktop-view-main-wrapper {
    display: block;
    background-color: #1a85eb;
    padding: 15px 90px 15px 90px;
  }

  #navbar-desktop-view-logo-wrap {
    width: 10%;
  }

  #navbar-desktop-view-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #navbar-desktop-view-search-wrap {
    display: flex;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    width: 18%;
    height: 30px;
    padding: 5px;
    margin-top: 5px;
    margin-left: 35px;
  }

  #navbar-desktop-view-search-icon {
    color: #fff;
    font-size: 23px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 3px;
  }

  #navbar-desktop-view-search-wrap > input {
    height: 30px;
    width: 70%;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.86);
    font-family: "Nunito";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.25px;
  }

  #navbar-desktop-view-search-wrap > input::placeholder {
    color: rgba(255, 255, 255, 0.86);
    font-family: "Nunito";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.25px;
  }

  #navbar-desktop-view-search-wrap > input:focus {
    outline: none;
  }

  #navbar-desktop-view-elements-wrap {
    display: flex;
  }

  #navbar-desktop-view-hyperlinks-wrap {
    display: flex;
    width: 35%;
    justify-content: space-around;
    margin-left: 250px;
    height: 30px;
    margin-top: 15px;
  }

  #navbar-desktop-view-hyperlinks-wrap > a {
    color: #fff;
    text-decoration: none;
  }

  #navbar-desktop-view-hyperlinks-wrap > a > div {
    color: #fff;
    font-family: "Nunito";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  #navbar-desktop-view-contact-button {
    border-radius: 23px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 30px;
    color: #1a85eb;
    font-family: "Nunito";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.25px;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  
}
