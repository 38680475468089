#search-popup-main-wrap{
    padding-top: 30px;
}

#search-popup-header-wrap{
    display: flex;
    justify-content: space-around;
}

#search-popup-header-arrow-icon{
    font-size: 30px;
    margin-top: 8px;
}

#search-popup-header-search-input{
    border-radius: 7px;
border: 0.5px solid rgba(90, 84, 84, 0.48);
width: 100%;
height: 45px;
flex-shrink: 0;
padding-left: 12px;
padding-right: 10px;
}

#search-popup-header-search-wrap{
width: 65%;
}

#search-popup-header-search-input::placeholder{
    color: rgba(150, 146, 146, 0.67);
font-family: "Nunito";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.25px;
}

#search-popup-header-search-input:focus{
    outline: none;
}

#search-popup-header-search-icon-wrap{
    background: #1A85EB;
    width: 45px;
    border-radius: 7px;
    height: 45px;
}

#search-popup-header-search-icon{
    color: white;
    font-size: 27px;
    margin-top: 7px;
}

#search-popup-trending-searches-wrap{
    margin-top: 45px;
    padding-left: 17px;
}

#search-popup-trending-searches-heading{
    color: #030303;
font-family: "Nunito";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: left;
}

#search-popup-trending-searches-content-01-wrap{
    display: flex;
    border-radius: 5px;
border: 0.5px solid rgba(90, 84, 84, 0.45);
background: #FFF;
}

#search-popup-trending-searches-content-02-wrap{
    display: flex;
    border-radius: 5px;
border: 0.5px solid rgba(90, 84, 84, 0.45);
background: #FFF; 
}