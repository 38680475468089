.home-page-banner-slider-title {
  color: #fff;
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-page-banner-slider01 {
  background: url("../bg-img/Home/slider01-img01.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.home-page-banner-slider02 {
  background: url("../bg-img/Home/slider01-img02.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.home-page-banner-slider03 {
  background: url("../bg-img/Home/slider01-img03.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.home-page-banner-slider-quote {
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  width: 75%;
  margin: auto;
  margin-top: 10px;
}

.home-page-banner-slider-request-btn {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-size: 10px;
  font-family: "Inder", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 6px;
  margin-top: 26px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

#home-page-banner-slider-wrap {
  height: 296px;
}

.homepage-banner-slider-component {
  height: 296px;
}

.homepage-banner-slider-component > .slick-dots > li {
  margin-bottom: 50px;
}

.homepage-banner-slider-component > .slick-dots > li > button:before {
  font-size: 10px;
  color: #ffffff;
  opacity: 1;
}

#home-page-sec02-wrap {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  justify-content: space-between;
}

#home-page-sec02-wrap > div {
  width: 25%;
}

.home-page-sec02-logo-wrap {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  padding: 5px;
}

.home-page-sec02-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home-page-sec02-text {
  color: #033361;
  text-align: center;
  font-size: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: 90%;
  margin: auto;
  margin-top: 10px;
}

#home-page-sec02-text01 {
  width: 70%;
}

#home-page-sec02-logo01-wrap {
  background: rgba(221, 168, 32, 0.15);
}

#home-page-sec02-logo02-wrap {
  background: #ddedfc;
}

#home-page-sec02-logo03-wrap {
  background: rgba(200, 29, 228, 0.13);
}

#home-page-sec02-logo04-wrap {
  background: rgba(221, 168, 32, 0.15);
}

#home-page-sec03-wrap {
  margin-top: 54px;
}

#home-page-sec03-headline {
  color: #032d56;
  font-size: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-bottom: 30px;
}

.home-page-sec03-card-wrap {
  border-radius: 10px;
  border: 0.5px solid rgba(112, 112, 112, 0.36);
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  width: 85%;
  margin: auto;
  margin-bottom: 30px;
  padding: 3px 3px 0px 3px;
}

.home-page-sec03-card-title {
  color: rgba(3, 3, 3, 0.9);
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.home-page-sec03-card-rating {
  width: max-content;
  margin: auto;
  margin-top: 10px;
}

.home-page-sec03-card-description {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.013px;
  width: 70%;
  margin: auto;
  margin-top: 10px;
}

.home-page-sec03-card-button {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-family: "Inder";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px;
  width: 30%;
}

.home-page-sec03-card-image-wrap {
  width: 100%;
  margin: auto;
  border-radius: 10px 10px 0px 0px;
}

.home-page-sec03-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

#home-page-sec04-image-wrap {
  width: 100%;
}

#home-page-sec04-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home-page-sec04-headline {
  color: #013761;
  text-align: center;
  font-family: "Nunito";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 70%;
  margin: auto;
  margin-top: 30px;
}

#home-page-sec04-description {
  color: #023856;
  text-align: center;
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.26px;
  width: 85%;
  margin: auto;
  margin-top: 27px;
}

#home-page-sec04-button {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-family: "Inder";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 27px;
  padding: 8px 12px 8px 12px;
  cursor: pointer;
}

#home-page-sec04-wrap {
  background: #c4d1da;
  padding-bottom: 27px;
}

#home-page-sec05-wrap {
  background-image: url(../bg-img/Home/sec05.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 85%;
  margin: auto;
  height: 150px;
  margin-top: 40px;
  padding-left: 25px;
  padding-top: 40px;
  border-radius: 5px;
  padding-bottom: 25px;
}

#home-page-sec05-title {
  color: #033361;
  text-align: left;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

#home-page-sec05-description {
  color: rgba(0, 0, 0, 0.76);
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.195px;
  text-align: left;
  width: 80%;
  margin-top: 10px;
}

#home-page-sec05-button {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-family: "Inder";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  padding: 8px 20px 8px 20px;
  text-transform: uppercase;
}

#home-page-sec05-button-wrap {
  text-align: left;
  margin-top: 20px;
}

#home-page-sec06-wrap {
  margin-top: 30px;
}

#home-page-sec06-title {
  color: #032d56;
  text-align: center;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 90%;
  margin: auto;
}

.home-page-sec06-slide {
  border-radius: 5px;
  border: 0.5px solid rgba(112, 112, 112, 0.37);
  background: #fff;
  box-shadow: 1px 1px 3px 2px rgba(75, 160, 242, 0.12);
  padding: 5px;
}

.home-page-sec06-slide-img-wrap {
  width: 100%;
  height: 147px;
}

.home-page-sec06-slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-page-sec06-slide-title {
  color: #000;
  text-align: center;
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.013px;
  margin-top: 15px;
}

.home-page-sec06-slide-rating {
  width: max-content;
  margin: auto;
  margin-top: 5px;
}

.home-page-sec06-slide-description {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-family: "Nunito";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 10px;
}

.homepage-sec06-slider-component > .slick-list > div > div {
  margin-left: 20px;
  width: 5.9%;
}

.homepage-sec06-slider-component > .slick-list {
  width: 98%;
  height: 280px;
}

.homepage-sec06-slider-component {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.home-page-sec06-slide {
  padding-bottom: 15px;
}

.home-page-sec06-slide > a {
  text-decoration: none;
}

.home-page-sec07-slide > a {
  text-decoration: none;
}

#home-page-sec07-wrap {
  margin-top: 30px;
}

#home-page-sec07-title {
  color: #032d56;
  text-align: center;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 90%;
  margin: auto;
}

.home-page-sec07-slide {
  border-radius: 5px;
  border: 0.5px solid rgba(112, 112, 112, 0.37);
  background: #fff;
  box-shadow: 1px 1px 3px 2px rgba(75, 160, 242, 0.12);
  padding: 5px;
}

.home-page-sec07-slide-img-wrap {
  width: 100%;
  height: 147px;
}

.home-page-sec07-slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-page-sec07-slide-title {
  color: #000;
  text-align: center;
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.013px;
  margin-top: 15px;
}

.home-page-sec07-slide-rating {
  width: max-content;
  margin: auto;
  margin-top: 5px;
}

.home-page-sec07-slide-description {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-family: "Nunito";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 10px;
}

.homepage-sec07-slider-component > .slick-list > div > div {
  margin-left: 20px;
  width: 9.8%;
}

.homepage-sec07-slider-component > .slick-list {
  width: 98%;
  height: 280px;
}

.homepage-sec07-slider-component {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.home-page-sec07-slide {
  padding-bottom: 15px;
}

#home-page-sec08-wrap {
  background-image: url(../bg-img/Home/sec08.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 85%;
  margin: auto;
  height: 150px;
  margin-top: 40px;
  padding-left: 25px;
  padding-top: 40px;
  border-radius: 5px;
  padding-bottom: 25px;
}

#home-page-sec08-title {
  color: #033361;
  text-align: left;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

#home-page-sec08-description {
  color: rgba(0, 0, 0, 0.76);
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.195px;
  text-align: left;
  width: 80%;
  margin-top: 10px;
}

#home-page-sec08-button {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-family: "Inder";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  padding: 8px 20px 8px 20px;
  text-transform: uppercase;
}

#home-page-sec08-button-wrap {
  text-align: left;
  margin-top: 20px;
}

.home-page-sec09-user-image-wrap {
  width: 18%;
  height: 70px;
  border-radius: 5px;
}

.home-page-sec09-user-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
}

.home-page-sec09-testimonial-content {
  color: rgba(3, 3, 3, 0.9);
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.195px;
  width: 80%;
  margin: auto;
  text-align: left;
  margin-top: 25px;
  padding-right: 80px;
}

.home-page-sec09-user-name-rating-wrap {
  display: flex;
  margin-top: 42px;
}

.home-page-sec09-user-name {
  color: rgba(3, 3, 3, 0.9);
  font-family: "Nunito";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.225px;
}

.home-page-sec09-user-prof {
  color: rgba(3, 3, 3, 0.9);
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.195px;
  text-align: left;
  margin-top: 5px;
}

.home-page-sec09-slide {
  padding: 40px 28px 70px 28px;
}

#home-page-sec09-wrap {
  background: rgba(200, 226, 250, 0.4);
  margin-top: 40px;
}

.homepage-sec09-slider-component > .slick-dots > li {
  margin-bottom: 50px;
}

.homepage-sec09-slider-component > .slick-dots > li > button:before {
  font-size: 10px;
  color: #add8e6;
  opacity: 0.8;
}

.home-page-sec09-rating {
  margin-left: 18px;
}

@media only screen and (min-width: 1224px) {
  #home-page-banner-slider-wrap {
    height: 500px;
  }

  .home-page-banner-slider01 {
    height: 430px;
    padding-top: 80px;
  }

  .home-page-banner-slider02 {
    height: 430px;
    padding-top: 80px;
  }

  .home-page-banner-slider03 {
    height: 430px;
    padding-top: 80px;
  }

  .homepage-banner-slider-component {
    height: 500px;
  }

  .homepage-banner-slider-component > .slick-dots > li {
    margin-bottom: 70px;
  }

  .homepage-banner-slider-component > .slick-dots > li > button:before {
    font-size: 10px;
    color: #ffffff;
    opacity: 1;
  }

  .home-page-banner-slider-title {
    color: #fff;
    font-size: 48px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .home-page-banner-slider-quote {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; /* 128.571% */
    letter-spacing: 0.35px;
    width: 70%;
  }

  .home-page-banner-slider-request-btn {
    padding-left: 30px;
    padding-right: 30px;
  }

  #home-page-sec03-headline {
    color: #032d56;
    font-family: "Nunito";
    letter-spacing: 0.24px;
    margin-bottom: 30px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-top: 80px;
  }

  #home-page-sec03-card-flex-wrap {
    display: flex;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .home-page-sec03-card-image-wrap {
    height: 239px;
  }

  .home-page-sec03-card-wrap {
    border-radius: 10px;
    border: 0.5px solid rgba(112, 112, 112, 0.36);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
    width: 25%;
    margin: auto;
    margin-bottom: 30px;
    padding: 3px 3px 0px 3px;
  }

  #home-page-sec04-wrap {
    display: flex;
    padding-bottom: 0px;
    height: 550px;
  }

  #home-page-sec04-headline {
    width: 55%;
    margin: 0px;
    margin-top: 70px;
    color: #013761;
    font-family: "Nunito";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }

  #home-page-sec04-description {
    color: #023856;
    font-family: "Inder";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
    letter-spacing: 0.36px;
    text-align: left;
    width: 80%;
    margin: 0px;
     margin-top: 30px;
  }

  #home-page-sec04-content-wrap{
    padding-left:60px;
    width: 40%;
  }

  #home-page-sec04-button{
    float: left;
    margin-top: 40px;
  }

  #home-page-sec04-image-wrap{
    width: 60%;
  }

  #home-page-sec05-wrap {
  width: 45%;
  margin-bottom: 60px;
  margin-top: 80px;
}

#home-page-sec06-title {
  color: #032D56;
font-family: Nunito;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.6px;
}

.homepage-sec06-slider-component > .slick-list > div > div {
  margin-left: 60px;
  width: 5.5%;
}

.homepage-sec06-slider-component > .slick-list {
  width: 98%;
  height: 280px;
}

.homepage-sec06-slider-component > .slick-dots > li {
  margin-top: 10px;
}

.homepage-sec06-slider-component > .slick-dots > li > button:before {
  font-size: 10px;
}


#home-page-sec07-wrap {
  margin-top: 70px;
}

#home-page-sec07-title {
  color: #032D56;
font-family: Nunito;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.6px;
}

.homepage-sec07-slider-component > .slick-list > div > div {
  margin-left: 80px;
  width: 22%;
}

.homepage-sec07-slider-component > .slick-list {
  width: 98%;
  height: 280px;
}

.homepage-sec07-slider-component {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

  #home-page-sec08-wrap {
  width: 45%;
  margin-bottom: 60px;
  margin-top: 60px;
}

.homepage-sec09-slider-component{
  width: 80%;
  margin: auto;
}

.homepage-sec09-slider-component > .slick-list > div > div {
  margin-left: 28px;
  width: 7.9%;
  height: 370px;
  background: rgba(200, 226, 250, 0.4);
  margin-bottom: 40px;
  border-radius: 20px;
}

#home-page-sec09-wrap {
  background: none;
}

.homepage-sec09-slider-component > .slick-dots > li {
   margin-bottom: 0px;
}

}
