.mattress-page-banner-slider-title {
  color: #fff;
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mattress-page-banner-slider01 {
  background: url("../bg-img/Home/slider01-img01.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.mattress-page-banner-slider02 {
  background: url("../bg-img/Home/slider01-img02.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.mattress-page-banner-slider03 {
  background: url("../bg-img/Home/slider01-img03.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  height: 260px;
}

.mattress-page-banner-slider-quote {
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;
  width: 75%;
  margin: auto;
  margin-top: 10px;
}

.mattress-page-banner-slider-request-btn {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-size: 10px;
  font-family: "Inder", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 6px;
  margin-top: 26px;
  padding-left: 10px;
  padding-right: 10px;
}

#mattress-page-banner-slider-wrap {
  height: 296px;
}

.mattress-banner-slider-component {
  height: 296px;
}

.mattress-banner-slider-component > .slick-dots > li {
  margin-bottom: 50px;
}

.mattress-banner-slider-component > .slick-dots > li > button:before {
  font-size: 10px;
  color: #ffffff;
}

#mattress-page-sec02-headline{
    color: #032D56;
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.27px;
margin-bottom: 12px;
}

#mattress-page-sec02-wrap{
    padding-top: 40px;
}

.mattress-page-sec02-button{
color: #707070;
text-align: center;
font-family: "Inder";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 2px;
text-transform: uppercase;
border-radius: 5px;
border: 0.5px solid #696A6F;
background: #FFF;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
padding: 10px 20px 10px 20px;
width: 70%;
margin-top: 18px;
}

.mattress-page-sec02-button:focus{
background: #087AE9;
color: #FFF;
text-align: center;
font-family: 'Inder';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 2px;
text-transform: uppercase;
border: 0.5px solid #087AE9;
}

#mattress-page-sec03-headline{
    color: #032D56;
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.27px;
}

#mattress-page-sec03-wrap{
    padding-top: 40px;
}

#mattress-page-sec03-slider-wrap{
    margin-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
}


.mattress-page-sec03-card-wrap {
  border-radius: 10px;
  border: 0.5px solid rgba(112, 112, 112, 0.36);
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
  margin: auto;
  margin-bottom: 30px;
}

.mattress-page-sec03-card-title {
  color: rgba(3, 3, 3, 0.9);
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.mattress-page-sec03-card-rating {
  width: max-content;
  margin: auto;
  margin-top: 10px;
}

.mattress-page-sec03-card-description {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-family: "Nunito";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.013px;
  width: 80%;
  margin: auto;
  margin-top: 10px;
}

.mattress-page-sec03-card-button {
  border-radius: 5px;
  border: 0.5px solid #087ae9;
  background: #087ae9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  color: #fff;
  font-family: "Inder";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px;
  width: 30%;
}

.mattress-page-sec03-card-image-wrap {
  width: 98%;
  margin: auto;
}

.mattress-page-sec03-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mattress-sec03-slider-component> .slick-dots > li > button:before {
  font-size: 10px;
}

@media only screen and (min-width: 1224px) {

  #mattress-page-banner-slider-wrap {
    height: 450px;
  }

  .mattress-page-banner-slider01 {
    height: 370px;
    padding-top: 80px;
  }

  .mattress-page-banner-slider02 {
    height: 370px;
    padding-top: 80px;
  }

  .mattress-page-banner-slider03 {
    height: 370px;
    padding-top: 80px;
  }

 .mattress-banner-slider-component{
    height: 450px;
  }

  .mattress-banner-slider-component > .slick-dots > li {
    margin-bottom: 70px;
  }

  .mattress-banner-slider-component > .slick-dots > li > button:before {
    font-size: 10px;
    color: #ffffff;
    opacity: 1;
  }

  .mattress-page-banner-slider-title {
    color: #fff;
    font-size: 48px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mattress-page-banner-slider-quote {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; /* 128.571% */
    letter-spacing: 0.35px;
    width: 70%;
  }

  .mattress-page-banner-slider-request-btn {
    padding-left: 30px;
    padding-right: 30px;
  }

  #mattress-page-sec02-desktop-flex-view{
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
}

#mattress-page-sec02-desktop-flex-view>div{
  width: 30%;
}

.mattress-page-sec02-button{
font-size: 16px;
width: 95%;
margin-top: 0px;
}

#mattress-page-sec02-headline{
font-size: 30px;
letter-spacing: 0.6px;
}

#mattress-page-sec03-headline{
color: #033361;
font-family: Nunito;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.48px;
}

.mattress-sec03-slider-component{
  width: 100%;
  margin: auto;
}

.mattress-sec03-slider-component > .slick-list {
  height: 430px;
  width: 90%;
  margin: auto;
}

.mattress-page-sec03-card-wrap {
  height: 400px;
  padding-bottom: 20px;
}

.mattress-sec03-slider-component > .slick-list > div{
  margin: auto;
}

.mattress-sec03-slider-component > .slick-list > div>div {
  margin-left: 32px;
  width: 260px;
}

.mattress-page-sec03-card-image-wrap {
  width: 100%;
  height: 40%;
  margin: auto;
}


}