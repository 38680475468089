#customise-final-page-heading{
    color: #032d56;
  font-family: "Nunito";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 60px;
}

#customise-final-page-content-details-title{
    color: #032D56;
font-family: "Nunito";
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.5px;
}

#customise-final-page-content-details{
    color: rgba(0, 0, 0, 0.65);
font-family: "Nunito";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.036px;
margin-top: 15px;
}

#customise-final-page-content-call-back-button{
    border-radius: 8px;
border: 0.5px solid #1A85EB;
background: #1A85EB;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
color: #FFF;
font-family: "Inder";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
letter-spacing: 2px;
text-transform: uppercase;
width: 80%;
padding-top: 7px;
padding-bottom: 7px;
margin-top: 100px;
}

#customise-final-page-content-wrap{
    display: flex;
    width: 60%;
    margin: auto;
    margin-top: 50px;
    justify-content: space-between;
    border-radius: 5px;
    padding: 25px;
background: rgba(221, 237, 252, 0.17);
}

#customise-final-page-content-image-wrap{
   width: 50%;
   border-radius: 8px;
border: 0.1px solid lightgrey;
background: #fff;
}

#customise-final-page-content-image{
  width: 80%;
  height: 100%;
  object-fit: cover;
}

#customise-final-page-content-details-wrap{
    text-align: left;
    width: 47%;
    background: #fff;
}